import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, message, Row, Col } from "antd";
import TextArea from "antd/es/input/TextArea";
import { MakeApiCall } from "../../../../apis";

const BrandModalForm = ({
  setVisible,
  visible,
  userData,
  onSubmit,
  selectedRow,
}) => {
  const [form] = Form.useForm();
  const [submitLoading, setSubmitLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(selectedRow || {})?.length > 0) {
      form.setFieldsValue(selectedRow);
    } else {
      form.resetFields();
    }
  }, [selectedRow]);

  // Function to handle modal close
  const handleCancel = () => {
    setVisible(false);
  };

  const CreateBrand = async (data) => {
    setSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `${
          Object.keys(selectedRow || {})?.length > 0
            ? `brand/${selectedRow?.b_id}`
            : "brand"
        }`,
        Object.keys(selectedRow || {})?.length > 0 ? "PATCH" : "POST",
        data,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        onSubmit();
        setSubmitLoading(false);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        setSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  // Function to handle form submit
  const handleOk = () => {
    form
      .validateFields()
      .then((values) => {
        CreateBrand(values);
      })
      .catch((info) => {});
  };

  return (
    <>
      <Modal
        title={
          Object.keys(selectedRow || {})?.length > 0
            ? "Edit Brand"
            : "Add Brand"
        }
        visible={visible}
        okButtonProps={{ loading: submitLoading }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form form={form} layout="vertical" name="supply_form">
          {/* Brand Name */}
          <Form.Item
            label="Brand Name"
            name="b_name"
            rules={[
              { required: true, message: "Please input the brand name!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Contact Name */}
          <Form.Item
            label="Contact Name"
            name="contact_name"
            rules={[
              { required: true, message: "Please input the contact name!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Contact Email */}
          <Form.Item
            label="Contact Email"
            name="contact_email"
            rules={[
              { required: true, message: "Please input the contact email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input />
          </Form.Item>

          {/* Contact Note */}
          <Form.Item label="Contact Note" name="contact_note">
            <TextArea rows={3} />
          </Form.Item>

          {/* Phone */}
          <Form.Item
            label="Phone"
            name="phone_number"
            rules={[
              { required: true, message: "Please input the phone number!" },
              {
                pattern: new RegExp(
                  /^(\d{3}-\d{3}-\d{4}|\(\d{3}\) \d{3}-\d{4}|\d{10})$/
                ),
                message: "Please enter a valid phone number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="u_password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please input the password!",
                  },
                ]}
              >
                <Input.Password autocomplete="new-password" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="u_confirm_password"
                label="Confirm Password"
                dependencies={["u_password"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm the password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("u_password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Passwords do not match!")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password autocomplete="new-password" />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default BrandModalForm;
