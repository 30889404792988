import CentralLog from "../modules/users/application-logs/central-log.js";
import SystemLogs from "../modules/users/application-logs/system-log.js";
import CreateOrder from "../modules/users/create-order/index.js";
import PoTemplate from "../modules/users/create-order/po-template/index.js";
import Dashboard from "../modules/users/dashboard";
import FinancialSummary from "../modules/users/financial-summary";
import Insights from "../modules/users/insights";
import Inventory from "../modules/users/inventory";
import ItemMaster from "../modules/users/item-master/index.js";
import ComponentList from "../modules/users/item-master/lib/component-list.js";
import BrandSetup from "../modules/users/item-setup/brands-setup/index.js";
import SupplySetup from "../modules/users/item-setup/supply-setup/index.js";
import VendorsSetup from "../modules/users/item-setup/vendors-setup/index.js";
import LinkItems from "../modules/users/link-items/index.js";
import Mapping from "../modules/users/mapping/index.js";
import MarketplaceCredentials from "../modules/users/marketplace-credentials/index.js";
import EditPo from "../modules/users/po-list/Edit.js";
// import CreatePo from "../modules/users/po-list/create-po.js";
import PoList from "../modules/users/po-list/index.js";
import CreatePo from "../modules/users/po-list/PoCreate.js";
import ProfilePage from "../modules/users/profile";
import Purchase from "../modules/users/purchase";
import SubscribeAndSave from "../modules/users/subscribe-&-save";
import Variance from "../modules/users/variance";

export const Users = [
  {
    path: "dashboard",
    element: <Dashboard />,
  },
  {
    path: "insights",
    element: <Insights />,
  },
  {
    path: "variance",
    element: <Variance />,
  },
  {
    path: "purchase/purchase-analysis",
    element: <Purchase />,
  },
  {
    path: "inventory",
    element: <Inventory />,
  },
  {
    path: "subscribe-&-save",
    element: <SubscribeAndSave />,
  },
  {
    path: "financial-summary",
    element: <FinancialSummary />,
  },
  {
    path: "user-profile",
    element: <ProfilePage />,
  },
  {
    path: "callback_spapi",
    element: <MarketplaceCredentials />,
  },

  {
    path: "callback_ads",
    element: <MarketplaceCredentials />,
  },
  {
    path: "application-log/central-log",
    element: <CentralLog />,
  },
  {
    path: "application-log/system-log",
    element: <SystemLogs />,
  },
  {
    path: "mapping",
    element: <Mapping />,
  },
  {
    path: "item-master",
    element: <ItemMaster />,
  },
  {
    path: "component-list",
    element: <ComponentList />,
  },
  {
    path: "purchase/create-order",
    element: <CreateOrder />,
  },
  {
    path: "po-template",
    element: <PoTemplate />,
  },
  {
    path: "supply-setup",
    element: <SupplySetup />,
  },
  {
    path: "vendors-setup",
    element: <VendorsSetup />,
  },
  {
    path: "brand-setup",
    element: <BrandSetup />,
  },
  {
    path: "purchase/po-list",
    element: <PoList />,
  },
  // {
  //   path: "po-create",
  //   element: <CreatePo />,
  // },
  // {
  //   path: "/po-list?name=",
  //   component: lazy(() => import("../../views/order/list/Edit")),

  // },
  {
    path: "purchase/po-list/Approve/:id",
    element: <EditPo />,
  },
  {
    path: "purchase/po-list/:po",
    element: <EditPo />,
  },
  {
    path: "purchase/po-list/:po/:replacementId/:vendorId/:status",
    element: <CreatePo />,
  },
  // {
  //   path: "/po-list/create",
  //   component: lazy(() => import("../../views/order/list/Edit")),

  // },
  // {
  //   path: "/po-list/Approve/:id",
  //   component: lazy(() => import("../../views/order/list/Edit")),

  // },
  // {
  //   path: "/po-list/:id?",
  //   component: lazy(() => import("../../views/order/list/Edit")),

  // },
  {
    path: "link-items",
    element: <LinkItems />,
  },
];
