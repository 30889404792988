import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Modal, Space, Table, Typography } from "antd";
import { Link } from "react-router-dom";

const LinkAndUnLinkComponent = (props) => {
  const columns = [
    {
      title: "ASIN",
      dataIndex: "asin",
      key: "asin",
      width: "40px",
      render: (text) => <Typography.Text strong>{text}</Typography.Text>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "220px",
      render: (text, row) => (
        <Space direction="vertical">
          <Typography.Text strong>{text}</Typography.Text>
          {row?.components?.map((d) => (
            <span>
              {d?.index}.&nbsp;{d?.description}
            </span>
          ))}
        </Space>
      ),
    },
    {
      title: "Link",
      dataIndex: "is_linked",
      key: "is_linked",
      width: "50px",
      render: (text) =>
        !text ? (
          <Link href={text}>
            <Icon
              icon="material-symbols:link-rounded"
              width={24}
              style={{ marginRight: "10px" }}
            />
            Link
          </Link>
        ) : null,
    },
  ];

  return (
    <Modal
      title="Add To Existing Purchase Order"
      open={props.isOpen}
      onCancel={props.closeModal}
      centered
      footer={[
        <Button
          key="close"
          type="primary"
          size="small"
          onClick={props.closeModal}
        >
          Close
        </Button>,
      ]}
      width={800}
    >
      <Table
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={props.linkList}
        pagination={false}
      />
    </Modal>
  );
};

export default LinkAndUnLinkComponent;
