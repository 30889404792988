import React from "react";
// import { Button, ModalFooter, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button, Modal, Table } from "antd";

const AddComponent = (props) => {
  const {
    show,
    data,
    close,
    submit,
    vendor,
    brandList,
    setSelectRows,
    selectRows,
  } = props;
  const columns = [
    {
      title: "Component ID",
      dataIndex: "component_id",
    },
    {
      title: "Item Description",
      dataIndex: "item_description",
    },
    {
      title: "Unit Cost",
      dataIndex: "unit_cost",
    },
    {
      title: "Brand",
      dataIndex: ["brands", "b_name"],
      render: (row) => <a>{row}</a>,
    },
    {
      title: "Vendor",
      dataIndex: ["vendors", "name"],
      render: (row) => <a>{row}</a>,
    },
    {
      title: "UPC",
      dataIndex: "upc",
    },
  ];

  const rowSelection = {
    onChange: (_, selectedRows) => {
      setSelectRows(selectedRows);
    },
  };

  console.log(show, "show");

  return (
    <Modal
      open={show}
      onCancel={() => {
        close();
      }}
      title="Add Componant"
      footer={[
        <Button
          type="primary"
          onClick={() => {
            selectRows.length === 0 ? alert("Select Components!!") : submit();
          }}
        >
          Add
        </Button>,
        <Button
          color="primary"
          variant="filled"
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>,
      ]}
    >
      <div style={{ maxHeight: "400px", overflow: "auto" }}>
        <Table
          rowSelection={{
            ...rowSelection,
          }}
          selectedRows={selectRows}
          rowKey="id"
          columns={columns}
          scroll={{ x: "max-content" }}
          dataSource={data}
          pagination={false}
        />
      </div>
      <></>
    </Modal>
  );
};
export default AddComponent;
