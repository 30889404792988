import { Icon } from "@iconify/react/dist/iconify.js";
import { Button, Popconfirm, Spin, Switch, Tag, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import { TableLoading } from "../../../Components/table-animation";
import Table2 from "../../../Components/tables/table2";
import {
  ConvertParams,
  DefaultAdminPage,
  DefaultUserPage,
  FormatNumber,
  Types,
  URL,
} from "../../../config";
import AccountModal from "./AccountModal";

const findRoutes = {
  admin: DefaultAdminPage,
  user: DefaultUserPage,
};

export default function ManageUser({ userData, setUser }) {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingAccount, setEditingAccount] = useState(null);

  const [modalLoading, setModalLoading] = useState(false);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  const [totalPage, setTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(0);

  const [searchText, setSearchText] = useState("");

  const getUserList = async (data) => {
    const response = await MakeApiCall(
      `users${ConvertParams(data)}`,
      "get",
      null,
      true,
      {},
      userData?.user?.auth_token
    );

    if (response?.status) {
      setTotalPage(response?.data?.pagination?.totalCount || 0);
      setCurrentPage(response?.data?.pagination?.page || 1);
      setPageSize(response?.data?.pagination?.page_size || 1);
      setList(response?.data?.records || []);
      setLoading(false);
    } else {
      message.warning(response?.message);
      setLoading(false);
      setList([]);
    }
  };
  const closeModal = () => {
    setIsModalVisible(false);
    setEditingAccount(null);
  };
  const addUser = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `sign-up`,
      "post",
      { ...data, rds_credential_id: 1, server_credential_id: 1 },
      true,
      {},
      userData?.user?.auth_token
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setLoading(true);
      getUserList({
        search: searchText,
        page: currentPage,
        pageSize: pageSize,
      });
      setModalLoading(false);
      closeModal();
    } else {
      message.destroy();
      setModalLoading(false);
      message.warning(response?.message);
    }
  };
  const updateUser = async (id, data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user-update/${id}`,
      "PATCH",
      data,
      true,
      {},
      userData?.user?.auth_token
    );

    if (response?.status) {
      message.destroy();
      message.success(response?.message);
      setLoading(true);
      getUserList({
        search: searchText,
        page: currentPage,
        pageSize: pageSize,
      });
      setModalLoading(false);
      closeModal();
    } else {
      message.destroy();
      setModalLoading(false);
      message.warning(response?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    getUserList({ search: searchText, page: currentPage, pageSize: pageSize });

    return () => {};
  }, []);

  const showModal = () => setIsModalVisible(true);

  const handleFormSubmit = (values) => {
    if (Object.keys(editingAccount || {})?.length > 0) {
      updateUser(editingAccount?.u_id, values);
      return;
    }
    addUser(values);
    setModalLoading(true);
  };

  const handleEdit = (record) => {
    setEditingAccount(record);
    setIsModalVisible(true);
  };

  const handleDelete = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user-delete/${data?.u_id}`,
      "delete",
      null,
      true,
      {},
      userData?.user?.auth_token
    );

    if (response?.status) {
      setLoading(true);
      getUserList({
        search: searchText,
        page: currentPage,
        pageSize: pageSize,
      });
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };
  const handleStatusChange = async (data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const response = await MakeApiCall(
      `user-update/${data?.u_id}`,
      "PATCH",
      {
        u_status: data?.u_status,
      },
      true,
      {},
      userData?.user?.auth_token
    );

    if (response?.status) {
      setLoading(true);
      getUserList({
        search: searchText,
        page: currentPage,
        pageSize: pageSize,
      });
      message.destroy();
      message.success(response?.message);
    } else {
      message.destroy();
      message.warning(response?.message);
    }
  };

  const SwitchAccount = async (id) => {
    const response = await MakeApiCall(
      `switch-user/${id}`,
      "get",
      null,
      true,
      {},
      userData?.user?.auth_token
    );

    if (Object.keys(response).length > 0) {
      setList((prev) =>
        prev.map((v) => (v.u_id === id ? { ...v, loadingAccount: false } : v))
      );
    }

    if (response?.status === true) {
      const type = Types?.[response?.data?.user_data?.u_type];
      const obj = {
        admin: userData,
        user: response?.data,
        loginType: type,
        token: response?.data?.auth_token_data?.access_token,
      };

      setUser(obj);
      navigate(findRoutes?.[type]);
    } else {
      message.warning(response?.message);
    }
  };

  const columns = [
    {
      title: "User Name",

      render: (d) => {
        return (
          <div className="d-flex align-items-center">
            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
              <img
                src={
                  URL + "/images/" + d?.u_photo || "assets/media/user-pic.jpg"
                }
                onError={(e) => {
                  e.target.src = "/assets/media/user-pic.jpg";
                }}
                alt
              />
            </div>
            <div className="d-flex flex-column">
              <a className="text-primary mb-1 fw-bolder gotham_black">
                {d?.u_name}
              </a>
              <span>{d?.u_email}</span>
            </div>
          </div>
        );
      },
    },
    {
      title: "Mobile No",
      dataIndex: "u_contact_no",
      key: "u_contact_no",
      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Pending Reports",
      dataIndex: "pending_reports",
      key: "pending_reports",
      render: (e) => {
        return <span>{FormatNumber(e || 0)}</span>;
      },
    },
    {
      title: "Switch User",
      align: "center",

      render: (d) => {
        return (
          <a
            onClick={() => {
              setList((prev) =>
                prev.map((v) =>
                  v.u_id === d.u_id ? { ...v, loadingAccount: true } : v
                )
              );
              SwitchAccount(d?.u_id);
            }}
            className="d-flex align-items-center py-2 justify-content-center text-dark"
          >
            {d?.loadingAccount ? (
              <Spin size="small" />
            ) : (
              <i className="ki-duotone ki-fasten fs-1 text-primary">
                <span className="path1" />
                <span className="path2" />
                <span className="path3" />
              </i>
            )}
            <span className="fw-bold text-dark ms-2">Switch Account</span>
          </a>
        );
      },
    },
    // {
    //   title: "Status",
    //   align: "center",
    //   render: (d) => {
    //     return (
    //       <div className="d-flex align-items-center py-2 justify-content-center">
    //         <span
    //           style={{ backgroundColor: d?.u_status ? "green" : "red" }}
    //           className="bullet bullet-dot  h-10px w-10px me-3"
    //         />
    //         <span className="fw-bold">
    //           {d?.u_status ? "Active" : "Inactive"}
    //         </span>
    //       </div>
    //     );
    //   },
    // },
    {
      title: "Role",
      align: "center",
      render: (d) => {
        return (
          <Tag
            color="blue"
            bordered={false}
            style={{ textTransform: "capitalize" }}
          >
            {Types?.[d?.u_type] || "-"}
          </Tag>
        );
      },
    },
    {
      title: "Created At",

      render: (d) => {
        return moment(new Date(parseInt(d.created_at) * 1000)).format(
          "MM/DD/YYYY hh:mm A"
        );
      },
    },
    {
      title: "Updated At",

      render: (d) => {
        return moment(new Date(parseInt(d.updated_at) * 1000)).format(
          "MM/DD/YYYY hh:mm A"
        );
      },
    },
    {
      title: "Status",

      render: (d) => {
        return (
          <Switch
            checkedChildren="Active"
            unCheckedChildren="Inactive"
            checked={d?.u_status}
            style={{
              backgroundColor: d?.u_status ? "green" : "red",
            }}
            onChange={(e) => {
              handleStatusChange({
                ...d,
                u_status: e ? 1 : 0,
              });
            }}
          />
        );
      },
    },
    {
      title: "Action",

      render: (d) => {
        return (
          <div className="d-flex flex-shrink-0 justify-content-center">
            <a
              className="btn btn-icon bg-light btn-active-color-primary btn-sm me-2"
              onClick={() => handleEdit(d)}
            >
              <i className="ki-outline ki-pencil fs-2 text-gray-800" />
            </a>
            {Types?.[d?.u_type] !== "admin" && (
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => handleDelete(d)}
                okText="Yes"
                placement="left"
                cancelText="No"
              >
                <a className="btn btn-icon bg-light btn-active-color-primary btn-sm">
                  <i className="ki-outline ki-trash fs-2 text-primary" />
                </a>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="fade-in-element ">
      <div className="row gy-5 g-xl-5">
        <div className="col-xxl-12">
          <div className="card card-xxl-stretch mb-5 mb-xl-8 ">
            <div className="card-header border-0">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label fw-bold fs-3 mb-1">
                  Users Statistics
                </span>
                <span className="text-muted mt-1 fw-semibold fs-7">
                  Over {totalPage} Users
                </span>
              </h3>
              <div className="card-toolbar">
                <div className="d-flex flex-wrap gap-4">
                  <div className="position-relative mr-4">
                    <i className="ki-outline ki-magnifier text-gray-800 fs-2 position-absolute top-50 translate-middle-y ms-4" />
                    <input
                      type="text"
                      data-kt-table-widget-4="search"
                      className="form-control  text-dark  w-250px fs-7 ps-12 all-search bg-white "
                      name="all_search"
                      placeholder="Search by name or email..."
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                    />
                  </div>
                  <Button
                    onClick={() => {
                      setList([]);
                      setLoading(true);
                      getUserList({
                        search: searchText,
                        page: currentPage,
                        pageSize: pageSize,
                      });
                    }}
                    type="primary"
                  >
                    <Icon icon="iconamoon:search-duotone" />
                  </Button>
                  <div className="position-relative">
                    <a
                      className="btn  fs-7 btn-primary fw-bold"
                      onClick={showModal}
                    >
                      Add New User
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body py-2">
              {loading ? (
                <TableLoading
                  id="test-table"
                  row={10}
                  columns={[1, 2, 3, 4, 5, 6]}
                  checkBoxVal={false}
                  actions={[1, 2]}
                />
              ) : (
                <Table2
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    pageSize: pageSize,
                    current: currentPage,
                    total: totalPage,
                    onChange: (e, f) => {
                      setLoading(true);
                      setList([]);
                      setPageSize(f);
                      setCurrentPage(e);
                      getUserList({
                        search: searchText,
                        page: e,
                        pageSize: f,
                      });
                    },
                    showSizeChanger: true, // To show page size options
                    pageSizeOptions: [10, 20, 50, 100], // Page size options
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  scroll={{ x: "max-content" }}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {isModalVisible && (
        <AccountModal
          visible={isModalVisible}
          onClose_={closeModal}
          onSubmit={handleFormSubmit}
          modalLoading={modalLoading}
          setEditingAccount={setEditingAccount}
          initialValues={editingAccount}
        />
      )}
    </div>
  );
}
