import React, { useState } from "react";

import { Table, Input, Button, Tooltip, message, Card } from "antd";
import { Icon } from "@iconify/react/dist/iconify.js";
import { ExpandWrapper } from "./style";
import Table1 from "../../../Components/tables/table1";

const ExpandableComponentUpdate = (props) => {
  const replenishmentQty = (row) => {
    const moq = parseFloat(row.fbaAllListing.moq || 0);
    const replenishmentQty = parseFloat(row.replenishment_qty || 0);
    if (row.replenishment_qty === "0") {
      if (row.salesSum && row.salesSum.replenishment_QTY) {
        if (moq > parseInt(row.salesSum.replenishment_QTY)) {
          return moq;
        }
        return parseInt(row.salesSum.replenishment_QTY);
      }
      return moq || 0;
    } else {
      if (moq > replenishmentQty) {
        return moq;
      }
      return replenishmentQty;
    }
  };
  const ORDERQUANTITYCAL = (row) => {
    if (row.order_quantity && typeof row.order_quantity === "number") {
      return row.order_quantity;
    }
    var oqty = parseInt(props.data.replenishment_qty) * parseInt(row.quantity);
    var orderQty =
      Math.ceil(oqty / parseInt(row.componentData.case_quantity)) *
      parseInt(row.componentData.case_quantity);
    return orderQty;
  };

  const CASEORDERQUANTITY = (row) => {
    return ~~(
      parseInt(ORDERQUANTITYCAL(row)) /
      parseInt(row.componentData.case_quantity)
    );
  };

  const purchaseCost = (e) => {
    return parseFloat(
      parseFloat(ORDERQUANTITYCAL(e) || 0).toFixed(2) *
        parseFloat(e["componentData"].unit_cost).toFixed(2)
    ).toFixed(2);
  };
  const [editState, setEditState] = useState(false);
  const numberWithCommas = (x) => {
    if (x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return x;
  };

  const NumberWithDecimal = (value) => {
    if (value) {
      let text = value.replaceAll(".", "");
      if (text?.length > 2) {
        return text.slice(0, -2) + "." + text.slice(-2);
      }
      return value;
    } else {
      return value;
    }
  };

  const purchaseCost_ = (item) => {
    return parseFloat(
      parseFloat(
        (parseFloat(item.componentData.unit_cost || 0).toFixed(2) -
          (props?.infoData?.discount_type === "percentage"
            ? parseFloat(
                (parseFloat(item.componentData.unit_cost || 0).toFixed(2) *
                  parseFloat(
                    item.discount || item?.componentData?.discount || 0
                  ).toFixed(2)) /
                  100
              ).toFixed(2)
            : parseFloat(
                item.discount || item?.componentData?.discount || 0
              ).toFixed(2))) *
          parseFloat(ORDERQUANTITYCAL(item)).toFixed(2)
      )
    ).toFixed(2) < 0
      ? 0
      : parseFloat(
          parseFloat(
            (parseFloat(item.componentData.unit_cost || 0).toFixed(2) -
              (props?.infoData?.discount_type === "percentage"
                ? parseFloat(
                    (parseFloat(item.componentData.unit_cost || 0).toFixed(2) *
                      parseFloat(
                        item.discount || item?.componentData?.discount || 0
                      ).toFixed(2)) /
                      100
                  ).toFixed(2)
                : parseFloat(
                    item.discount || item?.componentData?.discount || 0
                  ).toFixed(2))) *
              parseFloat(ORDERQUANTITYCAL(item) || 0).toFixed(2)
          )
        ).toFixed(2) || 0;
  };

  const columns = [
    {
      title: "Component ID",
      dataIndex: ["componentData", "component_id"],
      key: "component_id",
    },
    {
      title: "PO Description",
      dataIndex: ["componentData", "item_description"],
      key: "item_description",
    },
    {
      title: "Order Quantity",
      key: "order_quantity",
      render: (_, record) => {
        const orderQty = parseFloat(ORDERQUANTITYCAL(record)).toFixed(0);
        const caseQty = parseFloat(
          record?.componentData?.case_quantity
        ).toFixed(0);
        const isEdited =
          !editState &&
          replenishmentQty(props.data) === ORDERQUANTITYCAL(record);

        return (
          <div>
            <Input
              addonBefore={
                <Tooltip
                  title={`Ordered quantity changed from ${parseFloat(
                    props.data.replenishment_qty
                  )} to ${orderQty} due to Case Quantity of ${caseQty}`}
                >
                  <Icon
                    icon="material-symbols:info"
                    style={{
                      color: isEdited
                        ? "#555555"
                        : orderQty === 0
                        ? "red"
                        : "#c37c00",
                    }}
                    width={16}
                  />
                </Tooltip>
              }
              value={orderQty}
              style={{ width: "140px", margin: "10px 0" }}
              onChange={(e) => {
                setEditState(true);
                props.orderQuantity(e.target.value, props.data, record);
              }}
            />
          </div>
        );
      },
    },
    {
      title: "Case Order Quantity",
      key: "case_order_quantity",
      render: (record) => CASEORDERQUANTITY(record),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Component Cost",
      key: "unit_cost",
      render: (record) => `$${record?.componentData?.unit_cost || ""}`,
    },
    {
      title: "Discount",
      key: "discount",
      render: (record) => (
        <Input
          type="number"
          step=".01"
          className="w-100px"
          value={record?.discount || record?.componentData?.discount || ""}
          onChange={(e) => {
            const value = parseFloat(e.target.value);
            if (
              value >= 0 &&
              (props.infoData?.discount_type !== "percentage" || value <= 100)
            ) {
              props.updateDiscount(value, props.data, record);
            }
          }}
        />
      ),
    },
    {
      title: "Purchase Cost",
      key: "purchase_cost",
      render: (record) => {
        const purchaseCost = parseFloat(purchaseCost_(record)).toFixed(2);
        return (
          <div
            style={{
              background: purchaseCost === 0 ? "#efcece" : "#f9f9f9",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip
              title={`Component Cost $${parseFloat(
                record.componentData.unit_cost || 0
              ).toFixed(2)} - $${
                props?.infoData?.discount_type === "percentage"
                  ? parseFloat(
                      (parseFloat(record.componentData.unit_cost || 0).toFixed(
                        2
                      ) *
                        parseFloat(
                          record.discount ||
                            record?.componentData?.discount ||
                            0
                        ).toFixed(2)) /
                        100
                    ).toFixed(2)
                  : parseFloat(
                      record.discount || record?.componentData?.discount || 0
                    ).toFixed(2)
              }
    Discount = $${parseFloat(
      parseFloat(record.componentData.unit_cost || 0).toFixed(2) -
        (props?.infoData?.discount_type === "percentage"
          ? parseFloat(
              (parseFloat(record.componentData.unit_cost || 0).toFixed(2) *
                parseFloat(
                  record.discount || record?.componentData?.discount || 0
                ).toFixed(2)) /
                100
            ).toFixed(2)
          : parseFloat(
              record.discount || record?.componentData?.discount || 0
            ).toFixed(2))
    ).toFixed(2)} * ${parseFloat(ORDERQUANTITYCAL(record)).toFixed(
                0
              )} Units = Purchase Cost $${
                parseFloat(
                  parseFloat(
                    (parseFloat(record.componentData.unit_cost || 0).toFixed(
                      2
                    ) -
                      (props?.infoData?.discount_type === "percentage"
                        ? parseFloat(
                            (parseFloat(
                              record.componentData.unit_cost || 0
                            ).toFixed(2) *
                              parseFloat(
                                record.discount ||
                                  record?.componentData?.discount ||
                                  0
                              ).toFixed(2)) /
                              100
                          ).toFixed(2)
                        : parseFloat(
                            record.discount ||
                              record?.componentData?.discount ||
                              0
                          ).toFixed(2))) *
                      parseFloat(ORDERQUANTITYCAL(record)).toFixed(2)
                  )
                ).toFixed(2) < 0
                  ? 0
                  : parseFloat(
                      parseFloat(
                        (parseFloat(
                          record.componentData.unit_cost || 0
                        ).toFixed(2) -
                          (props?.infoData?.discount_type === "percentage"
                            ? parseFloat(
                                (parseFloat(
                                  record.componentData.unit_cost || 0
                                ).toFixed(2) *
                                  parseFloat(
                                    record.discount ||
                                      record?.componentData?.discount ||
                                      0
                                  ).toFixed(2)) /
                                  100
                              ).toFixed(2)
                            : parseFloat(
                                record.discount ||
                                  record?.componentData?.discount ||
                                  0
                              ).toFixed(2))) *
                          parseFloat(ORDERQUANTITYCAL(record)).toFixed(2)
                      )
                    ).toFixed(2)
              }`}
              placement="right"
            >
              <Button type="link">
                {numberWithCommas(purchaseCost)}
                <Icon icon="material-symbols:info" width={20} />
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Vendor SKU",
      dataIndex: ["componentData", "vendor_sku"],
      key: "vendor_sku",
    },
    {
      title: "UPC",
      dataIndex: ["componentData", "upc"],
      key: "upc",
    },
    {
      title: "Case Quantity",
      dataIndex: ["componentData", "case_quantity"],
      key: "case_quantity",
    },
  ];

  return (
    <ExpandWrapper>
      <Card
        styles={{
          body: {
            margin: 0,
          },
        }}
      >
        <Table1
          dataSource={props.data.componentData || []}
          columns={columns}
          scroll={{ x: "max-content" }}
          rowKey={(record, index) => index}
          pagination={false}
          locale={{ emptyText: "No Unit History found" }}
        />
        <div style={{ margin: "10px 0px" }}>
          Total{" "}
          {props.data.componentData &&
            props.data.componentData !== undefined &&
            props.data.componentData.length}{" "}
          item.
        </div>
      </Card>
    </ExpandWrapper>
  );
};
export default ExpandableComponentUpdate;
