import {
  Button,
  Checkbox,
  Col,
  Drawer,
  message,
  Modal,
  Popconfirm,
  Row,
  Space,
  Typography,
} from "antd";
import React from "react";

import { Icon } from "@iconify/react/dist/iconify.js";
import { useState } from "react";
import { Form, Input, Radio, Select, Card, List } from "antd";
import Title from "antd/es/typography/Title";
import { MakeApiCall } from "../../../../apis";
import { AdvancedFilterWrapper } from "../style";

const { Option } = Select;

const AdvancedFilter = ({
  setMoreFilterView,
  moreFilterView,
  brandList,
  brandLoading,
  vendorList,
  vendorLoading,
  userData,
  advanceFilterLoading,
  advanceFilterList,
  selectedAdvanceFilter,
  setSelectedAdvanceFilter,
  getList,
  applyFilter,
  form,
}) => {
  const [createFilter, setCreateFilter] = useState(false);
  const [createSubmitLoading, setCreateSubmitLoading] = useState(false);

  const AdvanceFiltersAction = async (values) => {
    setCreateSubmitLoading(true);
    try {
      const response = await MakeApiCall(
        `advance-filter`,
        "POST",
        { ...values, saved_filter: "1" },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        form.resetFields();
        getList();
        setCreateFilter(false);
        setCreateSubmitLoading(false);
        setSelectedAdvanceFilter({ ...selectedAdvanceFilter, ...values });
        message.destroy();
        message.success("Filter added successfully");
      } else {
        message.destroy();
        setCreateSubmitLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setCreateSubmitLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const AdvanceFiltersDeleteAction = async (id) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `delete-filter/${id}`,
        "DELETE",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        message.destroy();
        message.success(response?.message);
        getList();
        setSelectedAdvanceFilter(null);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const handleSubmit = (values) => {
    form
      .validateFields()
      .then((values) => {
        AdvanceFiltersAction(
          selectedAdvanceFilter?.usf_id
            ? {
                ...values,
                user_filter_id: selectedAdvanceFilter?.usf_id,
              }
            : values
        );
      })
      .catch((error) => {
        console.log("Error submitting form:", error);
      });
    console.log("Submitted values:", values);
  };

  const handleReset = () => {
    form.resetFields(); // Resets the form fields to their initial values
  };

  const submitFilter = () => {
    const allValues = form.getFieldsValue();

    const obj = {
      action: allValues?.link_type || selectedAdvanceFilter?.component_type,
      "filters[unit_cost]":
        allValues?.unit_cost || selectedAdvanceFilter?.unit_cost,
      "filters[single_pack]":
        allValues?.single_pack || selectedAdvanceFilter?.single_pack ? 1 : 0,
      "filters[multi_pack]":
        allValues?.multi_pack || selectedAdvanceFilter?.multi_pack ? 1 : 0,
      "filters[kit]": allValues?.kit || selectedAdvanceFilter?.kit ? 1 : 0,
      "filters[standard_size]":
        allValues?.standard_size || selectedAdvanceFilter?.standard_size
          ? 1
          : 0,
      "filters[oversize]":
        allValues?.oversize || selectedAdvanceFilter?.oversize ? 1 : 0,
      "filters[vendor_id]":
        allValues?.vendor_id || selectedAdvanceFilter?.vendor_id,
      "filters[brand_id]":
        allValues?.brand_id || selectedAdvanceFilter?.brand_id,
      search_type: "advance",
      filter_value:
        allValues?.user_filter_value ||
        selectedAdvanceFilter?.user_filter_value,
      usf_id: allValues?.usf_id || selectedAdvanceFilter?.usf_id,
    };
    applyFilter(obj);
  };

  return (
    <Modal
      title="Advanced Filter"
      onCancel={() => {
        setMoreFilterView(false);
      }}
      bodyStyle={{
        padding: "5px",
      }}
      open={moreFilterView}
      width={850}
      footer={[
        <Button className="me-3" onClick={submitFilter} type="primary">
          Apply
        </Button>,
        <Space>
          <Button
            color="primary"
            variant="filled"
            loading={createSubmitLoading}
            onClick={handleSubmit}
          >
            {selectedAdvanceFilter?.usf_id ? "Update" : "Create"}
          </Button>
          <Button type="link" onClick={handleReset}>
            Reset
          </Button>
        </Space>,
      ]}
    >
      <AdvancedFilterWrapper>
        <Row gutter={16}>
          <Col span={10} style={{ maxHeight: "500px", overflow: "auto" }}>
            <Row
              style={{
                position: "sticky",
                top: 0,
                zIndex: 9,
                background: "#FFF",
              }}
              justify={"end"}
              gutter={10}
            >
              <Col>
                <Button
                  onClick={() => {
                    form.resetFields();
                    setCreateFilter(true);
                    setSelectedAdvanceFilter(null);
                  }}
                  type="primary"
                  size="small"
                >
                  <Icon icon="material-symbols:add" width={20} />
                </Button>
              </Col>
            </Row>
            <List
              dataSource={advanceFilterList}
              loading={advanceFilterLoading}
              style={{
                padding: "12px 0px",
              }}
              renderItem={(item) => (
                <List.Item
                  className={`${
                    selectedAdvanceFilter?.usf_id === item?.usf_id
                      ? "active-card-item"
                      : ""
                  } hover-list-item`}
                  onClick={() => {
                    if (selectedAdvanceFilter?.usf_id === item?.usf_id) {
                      setSelectedAdvanceFilter(null);
                      form.resetFields();
                      return;
                    }
                    form.setFieldsValue({
                      user_filter_name: item?.user_filter_name,
                      user_filter_value: item?.user_filter_value,
                      link_type: item?.component_type,
                      single_pack: item?.single_pack ? true : false,
                      multi_pack: item?.multi_pack ? true : false,
                      kit: item?.kit ? true : false,
                      standard_size: item?.standard_size ? true : false,
                      oversize: item?.oversize ? true : false,
                      vendor_id: item?.vendor_id,
                      brand_id: item?.brand_id,
                      unit_cost: item?.unit_cost,
                    });
                    setSelectedAdvanceFilter(item);
                  }}
                  actions={[
                    <Space>
                      <Popconfirm
                        title="Are you sure to delete this filter?"
                        onConfirm={() => {
                          AdvanceFiltersDeleteAction(item?.usf_id);
                        }}
                        placement="left"
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button type="link">
                          <Icon
                            icon="material-symbols:delete"
                            width={24}
                            style={{ color: "#ff5a5a" }}
                          />
                        </Button>
                      </Popconfirm>

                      {/* <Checkbox
                        checked={selectedAdvanceFilter?.usf_id === item?.usf_id}
                        onChange={() => {
                          setSelectedAdvanceFilter(
                            selectedAdvanceFilter?.usf_id === item?.usf_id
                              ? null
                              : item
                          );
                        }}
                      /> */}
                      {/* <span
                        style={{
                          color: "#0179af",
                          textDecoration: "underline",
                          marginLeft: "10px",
                          cursor: "pointer",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          setSelectedAdvanceFilter(item);
                          form.setFieldsValue({
                            user_filter_name: item?.user_filter_name,
                            user_filter_value: item?.user_filter_value,
                            link_type: item?.component_type,
                            single_pack: item?.single_pack ? true : false,
                            multi_pack: item?.multi_pack ? true : false,
                            kit: item?.kit ? true : false,
                            standard_size: item?.standard_size ? true : false,
                            oversize: item?.oversize ? true : false,
                            vendor_id: item?.vendor_id,
                            brand_id: item?.brand_id,
                            unit_cost: item?.unit_cost,
                          });
                          setCreateFilter(true);
                        }}
                      >
                        View Details
                      </span> */}
                    </Space>,
                  ]}
                >
                  <List.Item.Meta title={item?.user_filter_name} />
                </List.Item>
              )}
            />
          </Col>
          <Col span={14} style={{ maxHeight: "500px", overflow: "auto" }}>
            <Form
              form={form}
              layout="vertical"
              name="filter_form"
              autoComplete="off"
            >
              <Form.Item
                name="user_filter_name"
                label={<Typography.Text strong>Filter Name</Typography.Text>}
                rules={[
                  { required: true, message: "Please enter the filter name" },
                ]}
              >
                <Input placeholder="Name of filter" />
              </Form.Item>

              <Form.Item
                name="user_filter_value"
                label={<Typography.Text strong>Search Term</Typography.Text>}
              >
                <Input placeholder="Search by Brand, Prep Type, etc..." />
              </Form.Item>

              <Form.Item
                name="link_type"
                label={<Typography.Text strong>Component Type</Typography.Text>}
              >
                <Radio.Group>
                  <Radio value="linked">Links</Radio>
                  <Radio value="unlinked">Unlink</Radio>
                </Radio.Group>
              </Form.Item>

              <Row>
                <Col span={24}>
                  <Typography.Text strong>Prep Type</Typography.Text>
                </Col>
                <Col span={8}>
                  <Form.Item name="single_pack" valuePropName="checked">
                    <Checkbox>Single Unit</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="multi_pack" valuePropName="checked">
                    <Checkbox>Multi-Pack</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item name="kit" valuePropName="checked">
                    <Checkbox>Kit</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Typography.Text strong>Fulfillment Type</Typography.Text>
                </Col>
                <Col span={12}>
                  <Form.Item name="standard_size" valuePropName="checked">
                    <Checkbox>Standard size</Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="oversize" valuePropName="checked">
                    <Checkbox>Oversize</Checkbox>
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="vendor_id"
                label={<Typography.Text strong>Vendor</Typography.Text>}
              >
                <Select
                  size="large"
                  loading={vendorLoading}
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select vendor"
                  options={vendorList?.map((d) => ({
                    value: d?.id,
                    label: d?.name,
                  }))}
                />
              </Form.Item>

              <Form.Item
                name="brand_id"
                label={<Typography.Text strong>Brand</Typography.Text>}
              >
                <Select
                  size="large"
                  loading={brandLoading}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  placeholder="Select brand"
                  showSearch
                  allowClear
                  options={brandList?.map((d) => ({
                    value: d?.b_id,
                    label: d?.b_name,
                  }))}
                />
              </Form.Item>

              <Form.Item
                name="unit_cost"
                label={<Typography.Text strong>ASIN Cost</Typography.Text>}
              >
                <Input
                  type="number"
                  placeholder="Enter ASIN Cost"
                  min={0}
                  step={0.01}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>
        {/* <Card
          bodyStyle={{ padding: "0px 6px 0px 6px" }}
          title={
            <div className="d-flex justify-content-between align-items-center">
              List Of Preset Filter
              <Button
                onClick={() => {
                  form.resetFields();
                  setCreateFilter(true);
                  setSelectedAdvanceFilter(null);
                }}
                type="primary"
                size="small"
              >
                <Icon icon="material-symbols:add" width={20} />
              </Button>
            </div>
          }
          bordered={false}
        >
          
        </Card> */}
      </AdvancedFilterWrapper>
      {/* <Drawer
        open={createFilter}
        onClose={() => setCreateFilter(false)}
        title="New Filter"
        width={400}
        closable={false}
        extra={
          <Button onClick={submitFilter} size="small" type="primary">
            Apply
          </Button>
        }
      ></Drawer> */}
    </Modal>
  );
};

export default AdvancedFilter;
