import { Button, Form, Modal, Select } from "antd";

const { Option } = Select;

const CheckForVendors = (props) => {
  const { vendorModalList, createPOAction, selectedRows } = props;
  const [form] = Form.useForm();

  return (
    <Modal
      title="Vendor List"
      open={props.isOpen}
      onCancel={props.closeModal}
      centered
      footer={[
        <Button
          key="close"
          type="dashed"
          size="small"
          onClick={props.closeModal}
        >
          Close
        </Button>,
        <Button
          key="close"
          type="primary"
          size="small"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                createPOAction({
                  selectedIds: selectedRows,
                  selectedVendor: values?.selectedVendor,
                });
                props.closeModal();
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Save
        </Button>,
      ]}
      width={500}
    >
      <Form form={form} name="basic" autoComplete="off">
        <Form.Item
          label="Vendor"
          name="selectedVendor"
          rules={[{ required: true, message: "Please select an vendor!" }]}
        >
          <Select>
            {vendorModalList.map((option, index) => (
              <Option key={index} value={option?.id}>
                {option?.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>

      {/* <div className="listDataVendor" /> */}
      {/* vendorModalList */}
    </Modal>
  );
};

export default CheckForVendors;
