import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  Flex,
  Input,
  message,
  Popconfirm,
  Space,
  Switch,
  Tag,
} from "antd";

import Table2 from "../../../../Components/tables/table2";
import { Icon } from "@iconify/react/dist/iconify.js";
import VendorModalForm from "./add";
import { MakeApiCall } from "../../../../apis";
import { ConvertParams } from "../../../../config";

const VendorsSetup = ({ userData }) => {
  const [visible, setVisible] = useState(false);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    page: 1,
    "per-page": 20,
    totalPage: 0,
  });
  const [selectedRow, setSelectedRow] = useState({});

  const columns = [
    {
      title: "#",
      render: (_, __, i) => {
        return (
          <span>{(filters?.page - 1) * filters?.["per-page"] + 1 + i}</span>
        );
      },
    },
    {
      title: "Vendor Name",
      dataIndex: "name",
      key: "name",
      render: (e) => {
        return <b>{e}</b>;
      },
    },
    {
      title: "Lead Time",
      dataIndex: "lead_time",
      key: "lead_time",
      render: (e) => (
        <Tag bordered={false} color="blue">
          {e}
        </Tag>
      ),
    },
    {
      title: "Outbound Lead Time",
      dataIndex: "outbound_lead_time",
      key: "outbound_lead_time",
    },
    {
      title: "Days of Stock",
      dataIndex: "days_of_stock",
      key: "days_of_stock",
    },
    {
      title: "Phone No",
      dataIndex: "phone_no",
      key: "phone_no",
    },
    {
      title: "Status",
      dataIndex: "is_enabled",
      key: "is_enabled",
      render: (text, record) => (
        <Switch
          defaultChecked={record.is_enabled}
          disabled
          // onChange={(checked) => handleStatusChange(record.key, checked)}
        />
      ),
    },
    {
      title: "Action",
      key: "action",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <Button
            color="primary"
            variant="solid"
            onClick={() => handleEdit(record)}
            icon={<Icon width={22} icon="iconamoon:edit-fill" />}
          ></Button>
          <Popconfirm
            title="Are you sure to delete this vendor?"
            onConfirm={() => handleDelete(record)}
            okText="Yes"
            placement="left"
            cancelText="No"
          >
            <Button
              color="danger"
              variant="filled"
              icon={<Icon width={22} icon="ic:round-delete" />}
            ></Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const ListVendor = async (data) => {
    const { totalPage, ...rest } = data;
    setLoading(true);
    try {
      const response = await MakeApiCall(
        `vendors${ConvertParams(rest)}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const { totalCount, page, page_size } = response?.data?.pagination;

        setFilters({
          page: page,
          "per-page": page_size,
          totalPage: totalCount,
        });

        setList(response?.data?.records || []);

        setLoading(false);
      } else {
        message.destroy();
        setLoading(false);
        message.warning(response?.message);
      }
    } catch (error) {
      setLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };
  const DeleteVenodr = async (id) => {
    try {
      const response = await MakeApiCall(
        `vendor/${id}`,
        "delete",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        ListVendor({
          ...filters,
        });
        setLoading(true);
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();

        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  useEffect(() => {
    ListVendor({
      ...filters,
    });
    setLoading(true);
    return () => {};
  }, []);

  // Edit handler
  const handleEdit = (record) => {
    setSelectedRow(record);
    setVisible(true);
  };

  // Delete handler
  const handleDelete = (record) => {
    DeleteVenodr(record.id);

    // Add your delete logic here
  };
  return (
    <Wrapper>
      <Flex justify="space-between" style={{ marginBottom: "10px" }}>
        <Input className="w-200px" placeholder="Search..." />

        <Button
          type="primary"
          onClick={() => setVisible(true)}
          icon={<Icon width={20} icon="material-symbols:add" />}
        >
          Add Vendor
        </Button>
      </Flex>
      <Table2
        dataSource={list}
        columns={columns}
        scroll={{ x: "max-content" }}
        loading={loading}
        pagination={{
          pageSize: filters?.["per-page"],
          current: filters?.page,
          total: filters?.totalPage,
          onChange: (e, f) => {
            const obj = {
              ...filters,
              page: e,
              "per-page": f,
            };
            setFilters(obj);
            ListVendor({
              ...obj,
            });
          },
          showSizeChanger: true, // To show page size options
          pageSizeOptions: [10, 20, 50, 100], // Page size options
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} of ${total} items`,
        }}
      />
      <VendorModalForm
        selectedRow={selectedRow}
        onSubmit={() => {
          setSelectedRow({});
          setVisible(false);
          ListVendor({
            ...filters,
          });
          setLoading(true);
        }}
        userData={userData}
        visible={visible}
        setVisible={() => {
          setVisible(false);
          setSelectedRow({});
        }}
      />
    </Wrapper>
  );
};

export default VendorsSetup;
