import { Breadcrumb } from "antd";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import LogoAnimation from "../Components/logo-animation";
import { FormatUserName } from "../config";
import Footer from "../layout/footer";
import Topbar from "../layout/topbar";

const Page = ({ user, setUser }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    setUser({});
    navigate("/login");
  };

  useEffect(() => {
    if (Object.keys(user)?.length === 0) {
      handleLogout();
    }
    setLoading(false);

    window.addEventListener("storage", (e) => {
      if (e?.storageArea?.length === 0) {
        setUser({});
        return navigate("/login");
      }

      // if (user) {
      //   navigate(
      //     !Object.keys(user)?.includes("admin") ? "/manage-user" : "/dashboard"
      //   );
      // }
    });
    return () => {};
  }, []);

  if (loading) {
    return <LogoAnimation />;
  }

  return (
    <>
      <div className="d-flex flex-column flex-root app-root" id="kt_app_root">
        <div
          className="app-page flex-column flex-column-fluid"
          id="kt_app_page"
        >
          <Topbar
            handleLogout={handleLogout}
            location={location}
            user={user}
            setUser={setUser}
            type={user?.loginType}
          />

          <div className="app-wrapper d-flex" id="kt_app_wrapper">
            <div
              className="app-main flex-column flex-row-fluid"
              id="kt_app_main"
            >
              <div className="d-flex flex-column flex-column-fluid">
                {HeaderTitles(location)}
                <div
                  id="kt_app_content"
                  style={{ height: "calc(100vh - 141px)", overflow: "auto" }}
                  className="app-content"
                >
                  <div
                    id="kt_app_content_container"
                    className="app-container container-fluid"
                  >
                    <Outlet userData={user} />
                  </div>
                </div>
              </div>
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Page;
function HeaderTitles(location) {
  console.log(
    [
      {
        href: "",
        title: <i className="ki-outline ki-home text-gray-700 fs-6" />,
      },

      {
        title: "Pages",
      },

      ...(location?.pathname?.includes("po-list") &&
      location?.pathname?.split("/")?.length > 2
        ? [{ title: "Po Creation" }]
        : location?.pathname
            ?.split("/")
            ?.filter((d) => d)
            ?.map((d) => ({
              title: FormatUserName(d),
            }))
      )?.filter((d) => d),
    ],
    "location?.pathname"
  );

  return (
    <div id="kt_app_toolbar" className="app-toolbar pt-2">
      <div
        id="kt_app_toolbar_container"
        className="app-container container-fluid d-flex align-items-stretch"
      >
        <div className="d-flex flex-stack flex-row-fluid">
          <div className="d-flex flex-row align-items-center flex-row-fluid ">
            <div className="page-title d-flex align-items-center me-3">
              <h1 className="page-heading d-flex text-gray-900 fw-bolder fs-1qx flex-column justify-content-center my-0">
                {FormatUserName(location?.pathname?.split("/").slice(-1)[0])}
              </h1>
            </div>
            <ul className="breadcrumb breadcrumb-separatorless fw-semibold mb-0">
              <li className="breadcrumb-item me-2">
                <i className="fs-7 text-gray-700">|</i>
              </li>
            </ul>
            <Breadcrumb
              separator={
                <i
                  className="ki-outline ki-right fs-5 text-gray-700 mx-n1"
                  style={{ lineHeight: "1.6" }}
                />
              }
              items={[
                {
                  href: "",
                  title: (
                    <i className="ki-outline ki-home text-gray-700 fs-6" />
                  ),
                },

                // {
                //   title: "Pages",
                // },

                ...(location?.pathname?.includes("po-list") &&
                !location?.pathname?.includes("purchase") &&
                location?.pathname?.split("/")?.length > 2
                  ? [{ title: "Po Creation" }]
                  : location?.pathname
                      ?.split("/")
                      ?.filter((d) => d)
                      ?.map((d) => ({
                        title: FormatUserName(d),
                      }))
                )?.filter((d) => d),
              ]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
