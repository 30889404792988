import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import {
  Button,
  DatePicker,
  Dropdown,
  Flex,
  Input,
  Menu,
  message,
  Popover,
  Select,
  Space,
  Tag,
  Tooltip,
} from "antd";
import Table2 from "../../../Components/tables/table2";
import { Icon } from "@iconify/react/dist/iconify.js";
import { Link } from "react-router-dom";
import { MakeApiCall } from "../../../apis";
import MarketplaceSelect from "../../../Components/marketplace";
import dayjs from "dayjs";
import { ConvertParams, FormatNumber, PaginationConfig } from "../../../config";

const PoList = (props) => {
  const { userData } = props;
  const [poList, setPoList] = useState([]);
  const [poLoading, setPoLoading] = useState(true);
  const [arrayPoUnitPrice, setArrayPoUnitPrice] = useState({});
  const [poDataAllRepArr, setPoDataAllRepArr] = useState([]);
  const [savedFilter, setSavedFilter] = useState([]);
  const [filterData, setFilterData] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({
    marketplace_id: null,
    page: 1,
    "page-size": 20,
    totalPage: 0,
    page_count: 0,
    "PurchaseOrdersSearch[filter_value]": null,
  });
  const [selectedRows, setSelectedRows] = useState([]);

  const [totalSum, setTotalSum] = useState(0);
  const [discountSum, setDiscountSum] = useState(0);
  const [shippingCost, setShippingCost] = useState(0);
  const [asinUnit, setAsinUnit] = useState(0);
  const [totalUnit, setTotalUnit] = useState(0);

  const GetPoListAction = async (data) => {
    const { totalPage, page_count, ...rest } = data;
    setPoLoading(true);
    setPoList([]);
    try {
      const response = await MakeApiCall(
        `all-po${ConvertParams(rest)}`,

        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status) {
        const processedData = Object.fromEntries(
          Object.entries(response.data.arrayPoUnitPrice).map(([key, item]) => {
            const totalSum = item.total.reduce(
              (sum, value) => sum + parseInt(value, 10),
              0
            );
            const totalPriceSum = item.total_price.reduce(
              (sum, value) => sum + value,
              0
            );

            return [
              key,
              { ...item, total_sum: totalSum, total_price_sum: totalPriceSum },
            ];
          })
        );
        setArrayPoUnitPrice(processedData);
        setPoDataAllRepArr(
          Object.entries(response.data.poDataAllRepArr).map(([id, values]) => ({
            id,
            value: values.reduce((a, b) => a + parseInt(b), 0),
          }))
        );
        if (response?.data?.records?.length > 0) {
          setSelectedFilters({
            ...data,
            page: response.data?.pagination?.page,
            "page-size": response.data?.pagination?.page_size,
            page_count: response.data?.pagination?.page_count,
            totalPage: response.data?.pagination?.totalCount,
          });
        }
        setSavedFilter(response.data.savedFilter || []);
        setPoList(response?.data?.records || []);
        setPoLoading(false);

        AllPoTotalList(`all-po-total${ConvertParams(rest)}`);
      } else {
        setPoList([]);
        setPoLoading(false);
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      setPoList([]);
      setPoLoading(false);
      message.destroy();
      message.error(error?.message);
    }
  };

  const AllPoTotalList = async (url) => {
    try {
      const response = await MakeApiCall(
        `${url}`,
        "get",
        null,
        true,
        {},
        userData?.user?.auth_token
      );

      if (response?.status === true) {
        const discountList = [];
        const unitList = [];
        const totalUnitList = [];
        const cost_Shipping = [];
        Object.entries(response.data.arrayPoUnitPrice).forEach((element) => {
          element?.[1]?.total?.forEach((totalCount) => {
            totalUnitList.push(parseInt(totalCount || 0));
          });
          element?.[1]?.discount?.forEach((discountCount) => {
            discountList.push(parseFloat(discountCount || 0));
          });
        });
        response.data.records.forEach((cost) => {
          cost_Shipping.push(parseFloat(cost.shipping_cost || 0));
        });
        Object.entries(response.data.poDataAllRepArr).forEach((asinUnit) => {
          asinUnit[1].forEach((unit) => {
            unitList.push(parseFloat(unit || 0));
          });
        });
        setAsinUnit(unitList.reduce((a, b) => a + b, 0));
        setTotalUnit(totalUnitList.reduce((a, b) => a + b, 0));
        setShippingCost(cost_Shipping.reduce((a, b) => a + b, 0));
        setDiscountSum(discountList.reduce((a, b) => a + b, 0));
        setTotalSum(
          parseFloat(response.data.allPoTotalApi_res_count).toFixed(2)
        );
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error(error?.message);
    }
  };

  const editNote = async (row) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const response = await MakeApiCall(
        `edit-note/${row.po_id}`,
        "PATCH",
        { note: row.note },
        true,
        {},
        userData?.user?.auth_token
      );
      if (response?.status === true) {
        message.destroy();
        message.success(response?.message);
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error("something went wrong");
    }
  };

  const callAfterMarketPlace = async (marketplace_id) => {
    if (!marketplace_id) return;
    try {
      setSelectedFilters({
        ...selectedFilters,
        marketplace_id: marketplace_id,
      });
      await GetPoListAction({
        ...selectedFilters,
        ...filterData,
        marketplace_id: marketplace_id,
      });
    } catch (error) {
      message.warning(error?.message);
    }
  };
  const MassFire = async (marketplace_id) => {
    if (!marketplace_id) return;
    try {
      setSelectedFilters({
        ...selectedFilters,
        marketplace_id: marketplace_id,
      });
      await GetPoListAction({
        marketplace_id: marketplace_id,
        ...filterData,
        ...selectedFilters,
      });
    } catch (error) {
      message.warning(error?.message);
    }
  };

  const DeletePo = async () => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const poList = selectedRows.map((d) => d.po_id.toString());
      if (poList.length !== 0) {
        const response = await MakeApiCall(
          `bulk-action`,

          "post",
          {
            selectedPoItems: poList,
            status: "Delete",
          },
          true,
          {},
          userData?.user?.auth_token
        );
        if (response?.status === true) {
          setSelectedRows([]);
          GetPoListAction({
            ...selectedFilters,
            ...filterData,
          });
          message.destroy();
          message.success(response?.message);
        } else {
          message.destroy();
          message.warning(response?.message);
        }
      } else {
        message.destroy();
        message.warning("Please select at least one record");
      }
    } catch (error) {
      message.destroy();
      message.warning("something went wrong");
    }
  };

  const columns = [
    {
      title: "Reference",
      render: (row) => {
        return (
          <Link
            className="table-row overflowText"
            style={{ color: "#337ab7", cursor: "pointer" }}
            to={`/purchase/po-list/${row.po_id || ""}`}
            target="_blank"
          >
            <Tag color="blue">{row.reference || "N/A"}</Tag>
          </Link>
        );
      },
    },
    {
      title: "Vendor",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (e) => {
        return <b>{e || "-"}</b>; // label
      },
    },
    {
      title: "Brand",
      dataIndex: "brand",
      key: "brand",
      render: (e) => {
        return <b>{e || "-"}</b>; // label
      },
    },
    {
      title: "PO Template",
      dataIndex: "template_label",
      key: "template_label", // label
      render: (e) => {
        return <b>{e || "-"}</b>; // label
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (e) => {
        return (
          <Tag bordered={false} color="blue">
            {e}
          </Tag>
        );
      },
    },
    {
      title: "Submission Date",

      render: (row) => (
        <div className="d-flex justify-content-center align-items-center overflowText">
          <span className="table-row overflowText">
            {!row.created_date
              ? dayjs(new Date(row.cdate * 1000)).format("MM-DD-YYYY") || ""
              : dayjs(new Date(row.created_date)).format("MM-DD-YYYY") || ""}
          </span>
          <Popover
            placement="bottomLeft"
            content={
              <div>
                <DatePicker
                  value={
                    !row.created_date
                      ? dayjs(new Date(row.cdate * 1000)) || ""
                      : dayjs(new Date(row.created_date)) || ""
                  }
                  onChange={(date) => {
                    if (date) {
                      MakeApiCall(
                        `edit-created-date/${row.po_id}`,
                        "patch",
                        {
                          created_at: date.valueOf() / 1000,
                        },
                        true,
                        {},
                        userData?.user?.auth_token
                      ).then((res) => {
                        if (res.status) {
                          message.success(res.message);
                          GetPoListAction({
                            ...filterData,
                            ...selectedFilters,
                          });
                        } else {
                          message.error(res.message);
                        }
                      });
                    }
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="p-2 ms-3" color="primary" variant="filled">
              <Icon width={20} icon="material-symbols:edit-outline" />
            </Button>
          </Popover>
        </div>
      ),
    },
    {
      title: "Estimated Inbound Delivery Date",
      key: "expected_date",
      align: "center",
      width: 250,
      render: (row) => {
        return (
          <div className="d-flex justify-content-flex-end align-items-center overflowText">
            <span className="table-row">
              {!row.expected_date
                ? "N/A"
                : dayjs(new Date(row.expected_date)).isValid()
                ? dayjs(new Date(row.expected_date)).format("MM-DD-YYYY") || ""
                : "N/A"}
            </span>
            <Popover
              placement="bottomLeft"
              content={
                <div>
                  <DatePicker
                    value={
                      row.expected_date
                        ? dayjs(new Date(row.expected_date))
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        MakeApiCall(
                          `edit-expected-date/${row.po_id}`,
                          "patch",
                          {
                            expected_date: date.format("YYYY-MM-DD"),
                          },
                          true,
                          {},
                          userData?.user?.auth_token
                        ).then((res) => {
                          if (res.status) {
                            message.success(res.message);
                            GetPoListAction({
                              ...filterData,
                              ...selectedFilters,
                            });
                          } else {
                            message.error(res.message);
                          }
                        });
                      }
                    }}
                  />
                </div>
              }
              trigger="click"
            >
              <Button className="p-2 ms-3" color="primary" variant="filled">
                <Icon width={20} icon="material-symbols:edit-outline" />
              </Button>
            </Popover>
            &nbsp;
            <Tooltip
              placement="left"
              title={`Submission Date ${
                dayjs(new Date(row.created_date)).format("MM-DD-YYYY") || ""
              } + Lead Time 
            ${row?.vendorName?.lead_time || 0} = ${
                dayjs(new Date(row.expected_date)).format("MM-DD-YYYY") || ""
              }`}
            >
              <Tag
                id={`popTop_${row.id || ""}`}
                className="p-2 ms-3"
                color="orange"
                bordered={false}
              >
                <Icon width={18} icon="material-symbols:info-outline" />
              </Tag>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Actual Inbound Delivery Date",
      key: "expected_date",
      align: "center",
      // width: 200,
      render: (row) => {
        const colorFind = () => {
          var g1 = new Date(row.expected_date);
          var g2 = new Date(row.actual_inbound_delivery_date);

          if (g1.getTime() < g2.getTime()) {
            return "red";
          }
          return "green";
        };
        return (
          <div className="d-flex justify-content-flex-end align-items-center overflowText">
            <span className="table-row">
              {dayjs(row.actual_inbound_delivery_date).isValid()
                ? dayjs(new Date(row.actual_inbound_delivery_date)).format(
                    "MM-DD-YYYY"
                  ) || ""
                : "N/A"}
            </span>
            &nbsp;
            <Popover
              placement="bottomLeft"
              content={
                <div>
                  <DatePicker
                    value={
                      row.actual_inbound_delivery_date
                        ? dayjs(new Date(row.actual_inbound_delivery_date))
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        MakeApiCall(
                          `edit-actual-inbound-delivery-date/${row.po_id}`,
                          "patch",
                          {
                            actual_inbound_delivery_date:
                              date.format("YYYY-MM-DD"),
                          },
                          true,
                          {},
                          userData?.user?.auth_token
                        ).then((res) => {
                          if (res.status) {
                            message.success(res.message);
                            GetPoListAction({
                              ...filterData,
                              ...selectedFilters,
                            });
                          } else {
                            message.error(res.message);
                          }
                        });
                      }
                    }}
                  />
                </div>
              }
              trigger="click"
            >
              <Button className="p-2 ms-3" color="primary" variant="filled">
                <Icon width={20} icon="material-symbols:edit-outline" />
              </Button>
            </Popover>
            {dayjs(row.actual_inbound_delivery_date).isValid() && (
              <Tag
                id={`popTop_${row.id || ""}`}
                color={colorFind()}
                className={`p-2 ms-3`}
                bordered={false}
              >
                <Icon width={18} icon="material-symbols:info-outline" />
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Estimated On-Shelf Date",
      key: "estimated_on_shelf_date",
      align: "center",
      width: 200,
      render: (row) => {
        const getDate = () => {
          console.log(row, "row");

          let createdDate = !row.created_date
            ? new Date(row.cdate * 1000)
            : new Date(row.created_date);
          return createdDate.setDate(
            createdDate.getDate() +
              (parseFloat(row?.outbound_lead_time || 0) +
                parseFloat(row?.lead_time || 0))
          );
        };
        return (
          <div
            className="d-flex overflowText align-items-center"
            style={{ justifyContent: "flex-end" }}
          >
            <span className="table-row">
              {dayjs(getDate()).isValid()
                ? dayjs(getDate()).format("MM-DD-YYYY") || ""
                : "N/A"}
            </span>
            &nbsp;
            <Tooltip
              placement="left"
              title={`Submission Date ${
                dayjs(
                  !row.created_date
                    ? new Date(row.cdate * 1000)
                    : new Date(row.created_date)
                ).format("MM-DD-YYYY") || ""
              } + Lead Time 
            ${row?.lead_time || 0} + Vender Outbound Lead Time ${
                row?.outbound_lead_time || 0
              } = ${dayjs(getDate()).format("MM-DD-YYYY") || ""}`}
            >
              <Tag bordered={false} color="blue" id={`popTop_${row.id || ""}`}>
                <Icon width={18} icon="material-symbols:info-outline" />
              </Tag>
            </Tooltip>
          </div>
        );
      },
    },
    {
      title: "Actual On-Shelf Date",
      key: "expected_date",
      align: "center",

      render: (row) => {
        const outboundLeadTime = () => {
          const template = [];
          return (
            (template &&
              template.length !== 0 &&
              template[parseInt(row.po_template)] &&
              parseFloat(
                template[parseInt(row.po_template)].outbound_lead_time
              )) ||
            0
          );
        };
        const colorFind = () => {
          const getDate = () => {
            let createdDate = new Date(row.created_date);
            return createdDate.setDate(
              createdDate.getDate() +
                (outboundLeadTime() + parseFloat(row?.lead_time))
            );
          };
          var g1 = new Date(dayjs(getDate()).format("MM-DD-YYYY"));
          var g2 = new Date(row.actual_on_shelf_date);
          if (g1.getTime() < g2.getTime()) {
            return "orange";
          }
          return "green";
        };
        return (
          <div className="d-flex justify-content-center align-items-center overflowText">
            <span className="table-row">
              {}
              {dayjs(row.actual_on_shelf_date).isValid()
                ? dayjs(new Date(row.actual_on_shelf_date)).format(
                    "MM-DD-YYYY"
                  ) || ""
                : "N/A"}
            </span>
            &nbsp;
            <Popover
              placement="bottomLeft"
              content={
                <div>
                  <DatePicker
                    value={
                      row.actual_on_shelf_date
                        ? dayjs(new Date(row.actual_on_shelf_date))
                        : null
                    }
                    onChange={(date) => {
                      if (date) {
                        MakeApiCall(
                          `edit-actual-on-shelf-date/${row.po_id}`,
                          "patch",
                          {
                            actual_on_shelf_date: date.format("YYYY-MM-DD"),
                          },
                          true,
                          {},
                          userData?.user?.auth_token
                        ).then((res) => {
                          if (res.status) {
                            message.success(res.message);
                            GetPoListAction({
                              ...filterData,
                              ...selectedFilters,
                            });
                          } else {
                            message.error(res.message);
                          }
                        });
                      }
                    }}
                  />
                </div>
              }
              trigger="click"
            >
              <Button className="p-2 ms-3" color="primary" variant="filled">
                <Icon width={20} icon="material-symbols:edit-outline" />
              </Button>
            </Popover>
            {/* <Button
              className="btn-icon btn-sm"
              color="info"
              // onClick={() =>
              //   setShowEditDateModal({
              //     title: "Actual On-Shelf Date",
              //     visible: true,
              //     value: row.actual_on_shelf_date,
              //     token: row.po_id,
              //   })
              // }
            >
              <Edit size={14} />
            </Button> */}
            {dayjs(row.actual_on_shelf_date).isValid() && (
              <Tag className="p-2 ms-3" bordered={false} color={colorFind()}>
                <Icon width={18} icon="material-symbols:info-outline" />
              </Tag>
            )}
            {/* {moment(row.actual_on_shelf_date).isValid() && (
              <Button.Ripple
                style={{ marginLeft: "4px" }}
                className={`btn-icon btn-sm ${colorFind()}`}
                id={`popTop_${row.id || ""}`}
              >
                <Info size={14} />
              </Button.Ripple>
            )} */}
          </div>
        );
      },
    },
    {
      title: "Discount",
      key: "discount",
      align: "center",
      width: 120,
      render: (row) => {
        return (
          <span className="table-row overflowText">
            ${""}
            {FormatNumber(parseFloat(row.discount || 0).toFixed(2))}
          </span>
        );
      },
    },
    {
      title: "Total",
      key: "total",
      align: "center",
      width: 120,
      render: (row) => {
        console.log(
          arrayPoUnitPrice?.[row?.po_id]?.total_price_sum,
          row?.po_id,
          "arrayPoUnitPrice"
        );

        return (
          <span className="table-row overflowText">
            ${""}
            {FormatNumber(
              parseFloat(
                arrayPoUnitPrice?.[row?.po_id]?.total_price_sum || 0
              ).toFixed(2)
            )}
          </span>
        );
      },
    },
    {
      title: "Total Units",
      dataIndex: "po_id",
      key: "po_id",
      render: (row) => {
        return (
          <span className="table-row overflowText">
            {FormatNumber(
              parseFloat(arrayPoUnitPrice?.[row]?.total_sum || 0).toFixed(2)
            )}
          </span>
        );
      },
    },
    {
      title: "ASIN Count",
      dataIndex: "total_asin_unit",
      key: "total_asin_unit",
      render: (total_asin_unit) => (
        <span className="table-row overflowText">
          {FormatNumber(total_asin_unit || 0)}
        </span>
      ),
    },
    {
      title: "Ordered ASIN Unit(s)",
      dataIndex: "replenishment_data_id",
      key: "replenishment_data_id",
      render: (replenishment_data_id) => {
        const value = () => {
          if (poDataAllRepArr.length !== 0) {
            const index = poDataAllRepArr.findIndex(
              (d) => parseInt(d.id) === parseInt(replenishment_data_id)
            );
            if (index !== -1) {
              return parseFloat(poDataAllRepArr[index]?.value || 0).toFixed(0);
            }
            return 0;
          }
          return 0;
        };
        return (
          <span className="table-row overflowText">
            {FormatNumber(value())}
          </span>
        );
      },
    },
    {
      title: "Updated At",
      dataIndex: "udate",
      key: "udate",
      render: (udate, row) => (
        <div className="d-flex justify-content-center align-items-center overflowText">
          <span className="table-row overflowText">
            {!udate
              ? "-"
              : dayjs(new Date(udate * 1000)).format(
                  "MMM DD, YYYY hh:mm:ss A"
                ) || ""}
          </span>
          <Popover
            placement="bottomLeft"
            content={
              <div>
                <DatePicker
                  value={udate ? dayjs(new Date(udate * 1000)) : null}
                  onChange={(date) => {
                    if (date) {
                      MakeApiCall(
                        `edit-updated-date/${row.po_id}`,
                        "patch",
                        { updated_at: date.valueOf() / 1000 },
                        true,
                        {},
                        userData?.user?.auth_token
                      ).then((res) => {
                        if (res.status) {
                          message.success(res.message);
                          GetPoListAction({
                            ...filterData,
                            ...selectedFilters,
                          });
                        } else {
                          message.error(res.message);
                        }
                      });
                    }
                  }}
                />
              </div>
            }
            trigger="click"
          >
            <Button className="p-2 ms-3" color="primary" variant="filled">
              <Icon width={20} icon="material-symbols:edit-outline" />
            </Button>
          </Popover>
        </div>
      ),
    },
    {
      title: "Note",

      render: (row) => (
        <span className="table-row overflowText">
          <Input
            placeholder="note"
            id="po_notes_edit"
            onBlur={() => {
              MakeApiCall(
                `edit-note/${row.po_id}`,
                "patch",
                { note: row?.note },
                true,
                {},
                userData?.user?.auth_token
              ).then((res) => {
                if (res.status) {
                  message.success(res.message);
                  GetPoListAction({
                    ...filterData,
                    ...selectedFilters,
                  });
                } else {
                  message.error(res.message);
                }
              });
            }}
            onPressEnter={() => {
              MakeApiCall(
                `edit-note/${row.po_id}`,
                "patch",
                { note: row?.note },
                true,
                {},
                userData?.user?.auth_token
              ).then((res) => {
                if (res.status) {
                  message.success(res.message);
                  GetPoListAction({
                    ...filterData,
                    ...selectedFilters,
                  });
                } else {
                  message.error(res.message);
                }
              });
            }}
            value={row.note || ""}
            onChange={(e) => {
              const newArr = poList.map((obj) => {
                if (obj.po_id === row.po_id) {
                  return { ...obj, note: e.target.value };
                }
                return obj;
              });
              setPoList(newArr);
            }}
          />
        </span>
      ),
    },
  ];

  const callActionPo = async (type) => {
    message.destroy();
    message.loading("Loading...", 0);
    try {
      const poList = selectedRows.map((d) => d?.po_id?.toString());
      const response = await MakeApiCall(
        `bulk-action`,
        "post",
        {
          selectedPoItems: poList,
          status: type,
        },
        true,
        {},
        userData?.user?.auth_token
      );

      if (response?.status === true) {
        message.destroy();
        message.success(response?.message);
        setSelectedRows([]);
        GetPoListAction({
          ...selectedFilters,
          ...filterData,
        });
      } else {
        message.destroy();
        message.warning(response?.message);
      }
    } catch (error) {
      message.destroy();
      message.error("something went wrong");
    }
  };

  const handleExport = async (value, data) => {
    message.destroy();
    message.loading("Loading...", 0);
    const { totalPage, page_count, ...rest } = data;
    const response = await MakeApiCall(
      `all-po${
        value === "perpage"
          ? `${ConvertParams({ ...rest, export: value })}`
          : `${ConvertParams({ ...rest, export: value }) || ""}`
      }`,
      "get",
      null,
      true,
      {},
      userData?.user?.auth_token
    );

    if (response.status === true) {
      const link = document.createElement("a");
      link.href = response.data.file;
      link.click();
      message.destroy();
      message.success(response.message);
    } else {
      message.destroy();
      message.warning(response.message);
    }
  };

  return (
    <Wrapper>
      <Flex className="mb-3" justify="space-between">
        <Space>
          <MarketplaceSelect
            callAfterMarketPlace={callAfterMarketPlace}
            showSearch
            MarketplaceData={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e?.[0]?.value,
              };
              setSelectedFilters(obj);
            }}
            onChange={(e) => {
              const obj = {
                ...selectedFilters,
                marketplace_id: e,
              };
              MassFire(obj);
              setSelectedFilters(obj);
            }}
            userData={userData}
            size="middle"
          />
          <Input
            size="middle"
            onChange={(e) => {
              setSelectedFilters({
                ...selectedFilters,
                "PurchaseOrdersSearch[filter_value]": e.target.value,
              });
            }}
            value={selectedFilters["PurchaseOrdersSearch[filter_value]"]}
            onPressEnter={() => {
              setPoLoading(true);
              GetPoListAction({
                ...filterData,
                ...selectedFilters,
              });
            }}
            placeholder="Search..."
          />
          <Select
            value={(filterData && filterData.user_filter_id) || null}
            options={savedFilter.map((d) => {
              return {
                value: d.usf_id,
                label: d.user_filter_name,
              };
            })}
            onChange={(_, obj) => {
              let filterData_ = { ...filterData };
              const index = savedFilter.findIndex(
                (d) => d.usf_id === obj.value
              );
              if (index !== -1) {
                filterData_["filter_value"] =
                  filterData_["PurchaseOrdersSearch[filter_value]"] ||
                  savedFilter[index].user_filter_value ||
                  "";
                filterData_["filters[vendor_id]"] =
                  savedFilter[index].vendor_id || "";
                filterData_["filters[brand_id]"] =
                  savedFilter[index].brand_id || "";
                filterData_["filters[status]"] =
                  savedFilter[index].status || "";
                filterData_["filters[po_template]"] =
                  savedFilter[index].po_template || "";
              }
              filterData_.user_filter_id = obj.value;
              const obj_ = {
                ...selectedFilters,
                page: 1,
                "page-size": selectedFilters["page-size"],
              };

              setPoLoading(true);
              GetPoListAction({
                ...filterData_,
                ...obj_,
              });
              setSelectedFilters(obj_);
              setFilterData(filterData_);
            }}
            placeholder="Saved Filter"
            className="w-175px"
          />
          <DatePicker.RangePicker
            style={{ height: "32px" }}
            size="middle"
            value={
              filterData?.to_date && filterData?.from_date
                ? [dayjs(filterData.from_date), dayjs(filterData.to_date)]
                : null
            }
            onChange={(date) => {
              const obj = {
                to_date: date[1].format("YYYY-MM-DD"),
                from_date: date[0].format("YYYY-MM-DD"),
              };
              setPoLoading(true);
              GetPoListAction({
                ...filterData,
                ...obj,
                ...selectedFilters,
              });
              setFilterData({ ...filterData, ...obj });
            }}
          />
          <Dropdown
            overlay={
              <Menu
                onClick={(e) => {
                  handleExport(e?.key, {
                    ...selectedFilters,
                    ...filterData,
                  });
                }}
              >
                <Menu.Item key="perpage">Export Current View</Menu.Item>
                <Menu.Item key="all">Export All</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button style={{ height: "32px" }} type="primary">
              Export
            </Button>
          </Dropdown>

          <Dropdown
            disabled={selectedRows?.length === 0}
            overlay={
              <Menu
                onClick={(e) => {
                  callActionPo(e?.key);
                }}
              >
                {selectedRows.findIndex((d) => d.status === "draft") !== -1 ? (
                  <Menu.Item key="Approve Draft">Approve Draft</Menu.Item>
                ) : selectedRows.findIndex((d) => d.status === "open") !==
                  -1 ? (
                  <>
                    <Menu.Item key="Close PO">Close PO</Menu.Item>
                    <Menu.Item key="Receive">Receive</Menu.Item>
                  </>
                ) : (
                  <Menu.Item key="Approve Draft">Re-open</Menu.Item>
                )}
              </Menu>
            }
            trigger={["click"]}
            placement="bottomRight"
          >
            <Button style={{ height: "32px" }} type="primary">
              Bulk Actions <Icon icon="bxs:down-arrow" />
            </Button>
          </Dropdown>
        </Space>
        <Space>
          <Button
            disabled={selectedRows?.length === 0}
            style={{ height: "32px" }}
            color="danger"
            onClick={() => {
              DeletePo();
            }}
            variant="filled"
          >
            Delete
          </Button>
        </Space>
      </Flex>
      <Table2
        rowSelection={{
          type: "radio",
          selectedRowKeys: selectedRows?.map((d) => d?.po_id),
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
          },
        }}
        rowKey="po_id"
        loading={poLoading}
        pagination={PaginationConfig({
          pageSize: selectedFilters["page-size"],
          currentPage: selectedFilters.page,
          totalPage: selectedFilters.totalPage,
          onChange: (page, pageSize) => {
            const obj = {
              ...selectedFilters,
              page: page,
              "page-size": pageSize,
            };
            setSelectedFilters(obj);
            setPoLoading(true);
            GetPoListAction({
              ...filterData,
              ...obj,
            });
          },
        })}
        scroll={{ x: "max-content" }}
        dataSource={poList}
        columns={columns}
      />
      <Flex justify="space-between" style={{ marginTop: "10px" }}>
        <Space>
          Total:{" "}
          <b>
            {"$"}
            {FormatNumber(parseFloat(totalSum).toFixed(2))}
          </b>
        </Space>
        <Space>
          Total Units: <b>{FormatNumber(parseFloat(totalUnit).toFixed(0))}</b>
        </Space>
        <Space>
          Ordered ASIN Unit(s):{" "}
          <b>{FormatNumber(parseFloat(asinUnit).toFixed(0))}</b>
        </Space>
        <Space>
          Shipping Cost:{" "}
          <b>
            {"$"}
            {FormatNumber(parseFloat(shippingCost).toFixed(2))}
          </b>
        </Space>
        <Space>
          Discount:{" "}
          <b>
            {"$"}
            {FormatNumber(parseFloat(discountSum).toFixed(2))}
          </b>
        </Space>
      </Flex>
    </Wrapper>
  );
};

export default PoList;
